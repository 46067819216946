<template>
    <!-- 素材列表页 -->
    <div class="personalInfo">
      <el-card>
        <div class="page">
          <div class="title">个人简介</div>
          <el-form>
            <el-form-item label="">
              <!-- <el-input type="textarea" :autosize="{ minRows: 10, maxRows: 28}" v-model="desc"></el-input> -->
              <vue-ueditor-wrap
                  v-model="desc"
                  :config="myConfig"
                  @ready="ready"
              ></vue-ueditor-wrap>
            </el-form-item>
          </el-form>
        </div>
      </el-card>
      <div class="save">
        <!-- <el-button type="primary" @click="Back">返回</el-button> -->
        <el-button class="themeButton" @click="updateDeveloperData">保存</el-button>
      </div>
    </div>
  </template>
  <style lang="scss" >
  .el-dialog__body {
    padding: 30px 20px;
    padding-top: 5px !important;
    color: #606266;
    font-size: 14px;
    word-break: break-all;
    padding-bottom: 30px;
    text-align: center;
  }
  .personalInfo .is-active {
    border-left: 0 !important;
  }
  .weight .el-table .el-table__cell{
    padding: 0 !important;
  }
  .personalInfo .el-tabs__item.is-active {
    color: #0D5570 !important;
    border-radius: 0.2083vw;
}
.personalInfo .el-tabs__content{
    overflow: visible;
}
.personalInfo .el-tabs__active-bar {
    height: 0.2083vw;
    border-radius: 0.2083vw;
    background-color: #0D5570;
}
.personalInfo .el-tabs__nav-wrap::after {
    content: "";
    height: 0.2083vw;
    border-radius: 0.2083vw;
    z-index: 0 !important;
}
.personalInfo .el-tabs__item{
    padding: 0 1.0417vw;
    // margin-right: 1.25rem;
    height:2.0833vw;
    // width: 4.375vw;
    text-align: center;
    font: normal normal normal 0.8333vw Source Han Sans CN;
}
.personalInfo .el-tabs{
    font: normal normal normal 0.8333vw Source Han Sans CN !important;
}
.personalInfo #edui1_elementpath{
display: none;
}
  </style>
  <style lang="scss" scoped>
  @import "@/assets/css/teacher/developer/personalInfo.scss";
  </style>
  
  <script>
  import personalInfo from "@/assets/js/teacher/developer/personalInfo.js";
  export default {
    ...personalInfo
  };
  </script>