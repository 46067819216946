import { request } from "@/request/http";

export default {
  getDeveloperData: (data) => {
    return request("POST", `/index.php/developer/getDeveloperData`, data);
  },

  updateDeveloperData: (data) => {
    return request("POST", `/index.php/developer/updateDeveloperData`, data);
  },
};