
import VueUeditorWrap from "vue-ueditor-wrap";
import { editorUploadUrl } from "@/utils/const";
import API from '@/api/teacher/developer/personalInfo.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
    name: "personalInfo",
    components: {
        VueUeditorWrap
    },
    data() {
        return{
            // ueditor 编辑器配置项
            myConfig: {
                // 控制添加题目框的显示与隐藏
                addshow:true,
                // 初始容器宽度
                initialFrameWidth: "70%",
                // 初始容器高度
                initialFrameHeight: 300,
                // 你的UEditor资源存放的路径,相对于打包后的index.html
                UEDITOR_HOME_URL: "./Editor/",
                // 编辑器不自动被内容撑高
                // autoHeightEnabled: false,
                // 如果需要上传功能,找后端小伙伴要服务器接口地址
                serverUrl: editorUploadUrl,
                // 关闭自动保存
                enableAutoSave: false,
                // 自定义工具栏，需要额外选项可以参考ueditor.config.js
                toolbars: [
                    [
                    "inserttable",//表格
                    "source", //源代码
                    "bold", //加粗
                    "italic", //斜体
                    "underline", //下划线
                    "strikethrough", //删除线
                    "fontborder", //字符边框
                    "blockquote", //引用
                    "selectall", //全选
                    "horizontal", //分隔线
                    "removeformat", //清除格式
                    "unlink", //取消链接
                    "deletecaption", //删除表格标题
                    "inserttitle", //插入标题
                    "cleardoc", //清空文档
                    "insertcode", //代码语言
                    "fontfamily", //字体
                    "fontsize", //字号
                    "insertimage", //多图上传
                    "link", //超链接
                    "emotion", //表情
                    "spechars", //特殊字符
                    "searchreplace", //查询替换
                    "insertvideo", //视频
                    "justifyleft", //居左对齐
                    "justifyright", //居右对齐
                    "justifycenter", //居中对齐
                    "forecolor", //字体颜色
                    "insertorderedlist", //有序列表
                    "insertunorderedlist", //无序列表
                    "imageleft", //左浮动
                    "imageright", //右浮动
                    "attachment", //附件
                    "imagecenter", //居中
                    "lineheight", //行间距
                    ],
                ],
            },
            // 简介
            desc:''
        }
    },
    created(){  
        this.getDeveloperData();
    },
    methods:{
        /**
         * @name: 获取editor示例
         * @author: camellia
         * @date: 2021-01-18 10:13:50
         */
        ready(editorInstance) {
            var self = this;
            editorInstance.focus();
            var str = "exam_paper_" + self.bank_id;
            // 添加自定义参数
            editorInstance.execCommand("serverparam", function(editor) {
                return {
                    articleid: str //设置富文本编辑器请求时，携带的额外参数
                };
            });
        },
        /**
         * @name: 获取开发者信息
         * @author: LXY
         * @date: 2022-10-31 10:13:50
         */
        getDeveloperData()
        {
            let self = this;
            let data = {};
            nprogressStart();
            self.$Loading.show();
            API.getDeveloperData(data)
                .then((res)=>{
                    nprogressClose();
                    self.$Loading.hide();
                    if(res.code > 0)
                    {
                        self.desc = res.desc
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message:res.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        })
                    }
                })
        },
        /**
         * @name: 修改开发者信息
         * @author: LXY
         * @date: 2022-10-31 10:13:50
         */
        updateDeveloperData()
        {
            let self = this;
            let data = {
                desc:self.desc
            }
            API.updateDeveloperData(data)
                .then((res)=>{
                    if(res.code > 0)
                    {
                        self.$Tips({
                            // 消息提示内容
                            message:res.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'success',
                            displayTime:1500
                        })
                    }
                    else
                    {
                        self.$Tips({
                            // 消息提示内容
                            message:res.msg,
                            // 消息提示类型（success-成功,warning-警告/失败）
                            messageType:'warning',
                            displayTime:1500
                        })
                    }
                })
        },
        
    }
}